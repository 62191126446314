.title {
    color: whitesmoke;
}

.textToHiraganaDiv {
    margin-left: auto;
    margin-right: auto;
}

.japaneseWordSounds {
    font-size: 70px;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 20px;
    display:block;
    color: whitesmoke;
}

.japaneseWordSoundsInput {
    font-size: 70px;
    width: 90%;
}

.soundVowels {
    color: white;
    font-weight: bolder;
    font-size: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
    display:block;
    letter-spacing: 20px;
}


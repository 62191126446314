.about {
    color: white;
}

.ipAddress {
    font-family: monospace, monospace;
}

.supportedMediaFormats {
    font-family: monospace, monospace;
}

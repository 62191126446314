.japaneseWordSounds {
    font-size: 70px;
    width: 90%;
    max-width: 360px;
    padding-top: 50px;
    padding-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.controlsColumn {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.japaneseWordSoundsInput {
    font-size: 70px;
    width: 360px;
}

.soundVowels {
    color: white;
    font-weight: bolder;
    font-size: 70px;
    width: 360px;
    padding-top: 20px;
    padding-bottom: 20px;
    display:block;
    letter-spacing: 20px;
}
.hiraganaSpan {
    color: white;
    white-space: nowrap;
    display: block;
}
.englishSpan {
    color: white;
    white-space: nowrap;
    display: block;
    font-size: 10px;
    padding: 5px;
}

.startButton, .stopButton {
    display: block;
    height: 50px;
}

.startButton {
    background-color: red;

}
.stopButton {
    background-color: gray;

}


#player {
    display: block;
}

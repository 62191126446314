.navigationTable {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.sound {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    font-size: 205px;
    color: white;
}

.sound.hidden {
    color: white;
    background-color: white;
}
.letter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
    font-size: 205px;
    color: darkgrey;
    white-space: nowrap;
}


.letter.hidden {
    color: darkgrey;
    background-color: darkgrey;
    cursor: pointer;
}

.unselectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.letter.hidden:hover {
    color: white;
    background-color: white;
}

.nextLetter {
    display: block;
    color: darkgrey;
}

.nextLetter:hover {
    cursor: pointer;
    font-size: 55px;
    color: white;
}

body {
  background-color: #282c34;
  width: 100%;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.statusMessageDiv {
  text-align: right;
  right: 0;
  position: absolute;
  padding-right: 20px;
}

.statusMessageSpan {

}

.pleaseLogonSpan {
  margin-top: 100px;
  width: 100%;
  color: white;
  display: block;
}



.pagecontent {
  padding-top: 64px;
  width: 100%;
}

.MuiToolbar-root {
  background-color: #3C4048FF;
  display: contents;
}

.App {
  text-align: center;
  width: 90%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}
 */

.App-body {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.App-link {
  color: #FFFFFF; /*#61dafb;*/
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

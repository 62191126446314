#navigationTable {
    margin-left: auto;
    margin-right: auto;
}

.unselectable {

}

.inputBox {
    padding: 10px;
}
.inputBox input {
    width: 80%;
    min-width: 80%;
    height: 40px;
    font-size: 30px;
}

.sound {
    width: 100%;
    font-size: 4em;
    color: white;
}

.sound.hidden {
    color: darkgray;
    background-color: darkgray;
}

.sound.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}


.japaneseWord {
    height: 14px;
    width: 100%;
    display: block;
    color: white;
    padding-bottom: 15px;
    padding-top: 15px;
}

@media screen and (max-width: 400px) {
    .sound, .japaneseWord, .word {
        font-size: 15vw !important;
        width: 95%;
    }

    .sound {
        height: unset;
    }
    .japaneseWord {
        padding-top: unset;
        padding-bottom: unset;
    }
}

.japaneseWord.hidden {
    color: darkgray;
    background-color: darkgray;
}

.japaneseWord.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}

.word {
    width: 100%;
    display: contents;
    font-size: 4em;
    color: darkgray;
}

.word.hidden {
    color: darkgray;
    background-color: darkgray;
}

.word.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}

.nextWord {
    display: block;
    font-size: 55px;
    color: darkgray;
}

.nextWord:hover {
    cursor: pointer;
    color: white;
}

#navigationTable {
    margin-left: auto;
    margin-right: auto;
}

.wordSpan {
    color: white;
}
.vowelsSpan {
    color: white;
}
.hiraganaSpan {
    color: white;
}
.katakanaSpan {
    color: white;
}
body {
    /*background-color: gray;*/
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.container {
    width: 550px;
    height: 250px;
    top: 10%;
    left: 10%;
}

.sigContainer {
    /* width: 550px;*/
    width: 100%;
    height: 250px;
    margin: 0 auto;
}

.sigPad {
    width: 550px;
    height: 250px;
    background-color: #FFFFFF;
}

.sigPad canvas {
    width: 550px;
    height: 250px;
}

.buttons {
    width: 100%;
    height: 30px;
}

.sigImage {
    background-size: 250px 50px;
    width: 550px;
    height: 250px;
    background-color: white;
}

.recognizedText {
    color: white;
    font-weight: bolder;
    font-size: 48px;
}



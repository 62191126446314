#navigationTable {
    width: 100%;
}


.sound {
    height: 150px;
    width: 100%;
    display: block;
    font-size: 8vw;
    color: white;
}


.sound.hidden {
    color: darkgray;
    background-color: darkgray;
}

.sound.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}

.japaneseWord {
    height: 14px;
    width: 40%;
    max-width: 300px;
    display: inline;
    color: white;
    padding-bottom: 15px;
    padding-top: 15px;
}

.japaneseWord.hidden {
    visibility: hidden;
}

.japaneseWord.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}

.word {
    height: 150px;
    width: 300px;
    display: contents;
    font-size: 8vw;
    color: darkgray;
}

@media screen and (max-width: 400px) {
    .sound, .word {
        font-size: 20vw;
    }
}

.word.hidden {
    color: darkgray;
    background-color: darkgray;
}

.word.hidden:hover {
    color: white;
    background-color: white;
    cursor: pointer;
}

.nextWord {
    display: block;
    font-size: 55px;
    color: darkgray;
}

.nextWord:hover {
    cursor: pointer;
    color: white;
}

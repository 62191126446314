.homeDiv {
    padding-top: 40px;
    color: black;
    width: 100%;
}

.homeTable {
    color: white;
    width: 100%;

}
.homeTable td {
    text-align: center;
}
